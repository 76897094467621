@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

:root {
  --primary: #1aac83;
  --error: #e7195a;
}
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  background: #f1f1f1;
  margin: 0;
  font-family: sans-serif;
}
header {
  background: #14be8e;
  height: 80px;
  margin: 0;
  padding: 0;
}
header .container {
  margin: 0 auto;
  padding: 5px 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #252628;
  text-decoration: none;
  font-weight: 900;
}
.pages {
  padding: 20px;
  padding-left: 140px;
  margin: 0 auto;
}

/* home */
.home{
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 10px;
}
.workout-details{
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}
.workout-details h4{
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p{
  margin: 0;
  padding-top: 10px;
  font-size: 0.9em;
  color: #555;
}
.workout-details span{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  /* background: #f1f1f1; */
  padding: 6px;
  /* border-radius: 50%; */
  color: #333;
}

/* workout form */
form{
  margin-left: 2rem;
}
label, input{
  display: flex; 
}
input{
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 70%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button{
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
form button:hover{
  background: #109670;
}
div .error{
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
  width: 70%;
}
input.error{
  border: 1px solid var(--error);
}
.deleteicon:hover{
  color: #e64848;
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a {
  margin-left: 10px;
}
nav button {
  background: #14be8e;
  color: #f0e7e7;
  border: 0.8px solid #fff;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}
nav button:hover{
  background: #0ecc96;
  color: #d4cfcf;

}
/* auth forms */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}